import { FC, useEffect, useRef } from 'react'
import { useIntersection } from 'react-use'

import styles from '../Network.module.scss'

import { useTexts } from '@/bus/texts'

interface Props {
  slug: string
  text: string
}

const Section: FC<Props> = ({ slug, text }) => {
  const intersectionRef = useRef(null)
  const { setTextsData } = useTexts()

  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.1
  })

  useEffect(() => {
    if (intersection?.isIntersecting) {
      setTextsData({ interSection: slug })
    }
    // eslint-disable-next-line
  }, [intersection?.isIntersecting, slug])

  return (
    <div className={styles['network__section']}>
      <div
        ref={intersectionRef}
        className={styles['network__section-anchor']}
      />
      <div id={slug} dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  )
}
export default Section
