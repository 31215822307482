import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'
import { FC, useEffect } from 'react'
import LazyLoad from 'react-lazy-load'

import styles from './Home.module.scss'
import { useUi } from '@/bus/ui'
import { AboutMain } from '@/sections/AboutMain'
import { Features } from '@/sections/Features'
import { Methods } from '@/sections/Methods'

const Partners = dynamic(
  () => import('@/sections/Partners').then(({ Partners }) => Partners),
  {
    ssr: false
  }
)

const Program = dynamic(
  () => import('@/sections/Program').then(({ Program }) => Program),
  {
    ssr: false
  }
)
const Security = dynamic(
  () => import('@/sections/Security').then(({ Security }) => Security),
  {
    ssr: false
  }
)

export const Home: FC = () => {
  const { t } = useTranslation('program')

  const { setSettings } = useUi()

  useEffect(() => {
    setSettings({ menu: true, footer: true })
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <AboutMain />
      <LazyLoad className={styles.home__partners}>
        <Partners />
      </LazyLoad>

      {/*  <LazyLoad className={styles.home__features}>*/}
      <Features className={styles.home__features} />
      {/*  </LazyLoad>*/}

      {/*  <LazyLoad className={styles.home__methods}>*/}
      <Methods className={styles.home__methods} />
      {/*  </LazyLoad>*/}

      <LazyLoad className={styles.home__program}>
        <Program
          heading={t('heading')}
          summary={t('summary')}
          table={t('table', { returnObjects: true })}
          notice={t('notice')}
        />
      </LazyLoad>
      <LazyLoad className={styles.home__security}>
        <Security />
      </LazyLoad>
    </>
  )
}
