import { useTranslation } from 'next-i18next'
import { NextSeo } from 'next-seo'
import { useEffect } from 'react'

import Link from '../../components/Link'

import styles from './error404.module.scss'
import { useUi } from '@/bus/ui'
import { routes } from '@/config'

export const Error404 = () => {
  const { t } = useTranslation()
  const { setSettings } = useUi()

  useEffect(() => {
    setSettings({ menu: false, fourOhFour: true, footer: false })
    return () => {
      setSettings({ fourOhFour: false })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <NextSeo title={'404'} />
      <div className={styles['fourOhFour']}>
        <div className={styles['fourOhFour__content']}>
          <div className={styles['fourOhFour__summary']}>
            <h1 className={styles['fourOhFour__heading']}>404</h1>
            <div
              className={styles['fourOhFour__about']}
              dangerouslySetInnerHTML={{ __html: t('error404:about') }}
            />
            <div className={styles['fourOhFour__back']}>
              <span
                dangerouslySetInnerHTML={{ __html: t('error404:you_can') }}
              />{' '}
              <Link
                href={routes.HOME}
                title={t('forms:buttons.goHome') as string}
              >
                <span
                  dangerouslySetInnerHTML={{ __html: t('error404:main_page') }}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
