import clsx from 'clsx'
import { FC, useCallback, useRef, useState } from 'react'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './SideTabs.module.scss'
import { useTexts } from '@/bus/texts'

interface SideTabsItemProps {
  header: string
  slug: string
}
interface SideTabsProps extends DefaultComponentProps {
  items: SideTabsItemProps[]
}
const SideTabs: FC<SideTabsProps> = ({ className = '', items = [] }) => {
  const itemsRef = useRef<HTMLAnchorElement[]>([])
  const [activeIndex, setActiveIndex] = useState(0)
  const [bgStyle, setBgStyle] = useState({ top: 0, height: 0 })

  const { interSection, setTextsData } = useTexts()

  const recalcBgStyles = useCallback((slug: number) => {
    const { offsetHeight: height = 0, offsetTop: top = 0 } =
      itemsRef.current?.[slug] || {}
    setBgStyle({ top, height })
  }, [])

  const handleEnter = useCallback(
    (index: number) => {
      recalcBgStyles(index)
    },
    [recalcBgStyles]
  )

  const handleLeave = useCallback(() => {
    recalcBgStyles(activeIndex)
  }, [activeIndex, recalcBgStyles])

  return (
    <nav className={clsx(styles['side-nav'], className)}>
      {items.map(({ header, slug }, index) => (
        <a
          title={header}
          key={index}
          className={clsx(styles['side-nav__link'], {
            [styles['side-nav__link_active']]: interSection === slug
          })}
          ref={el => (itemsRef.current[index] = el as HTMLAnchorElement)}
          onClick={() => setTextsData({ interSection: slug })}
          onMouseEnter={() => handleEnter(index)}
          onMouseLeave={handleLeave}
          href={`#${slug}`}
          dangerouslySetInnerHTML={{ __html: header }}
        />
      ))}
      <div className={styles['side-nav__bg']} style={bgStyle} />
    </nav>
  )
}
export default SideTabs
