import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FC, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import SideTabs from '@/components/SideTabs'

import styles from './Network.module.scss'
import { useTexts } from '@/bus/texts'
import { useUi } from '@/bus/ui'
import Section from '@/contents/Network/subcomponents/Section'
import { AboutNetwork, Reward } from '@/sections'

interface ContentProps {
  [key: string]: string | string[]
}

interface Props {
  network?: {}
}

export const Network: FC<Props> = ({ network = {} }) => {
  const router = useRouter()
  const { id: spaID = null } = useParams()
  const id = useMemo(() => spaID || router?.query?.id, [router.query, spaID])

  const {
    i18n: { language = 'ru' }
  } = useTranslation()

  const { setSettings } = useUi()
  const { getTextsBySlug, data, clearTexts } = useTexts()

  useEffect(() => {
    getTextsBySlug({ slug: 'network-page-' + id, lang: language })
    setSettings({ backButton: true, menu: true, footer: true })

    return () => {
      setSettings({ backButton: false })
      clearTexts()
    }
    // eslint-disable-next-line
  }, [])

  const {
    items = [],
    header = '',
    text = ''
  } = useMemo(() => ({ ...network, ...data }), [network, data])

  return (
    <>
      <AboutNetwork id={id as string} header={header} text={text} />
      <section className={styles['network']}>
        <div className={styles['network__side']}>
          <SideTabs items={items} />
        </div>
        <div className={styles.network__content}>
          {items.map(({ text = '', slug = '' }) => (
            <Section key={slug} slug={slug} text={text} />
          ))}
        </div>
      </section>
      <Reward />
    </>
  )
}
