import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

import Button from '@/components/Button'
import Icon from '@/components/Icon'
import Link from '@/components/Link'
import NetworkProps from '@/components/NetworkCard/NetworkProps.interface'

import networks from '@/config/networks'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './NetworkCard.module.scss'
import { routes } from '@/config'

const NetworkCard: FC<DefaultComponentProps & NetworkProps> = ({
  className = '',
  slug = '',
  header,
  text
}) => {
  const { t } = useTranslation()
  const { bg = '#000', color = '#fff' } = networks?.[slug] ?? {}
  return (
    <div className={clsx(styles['network-card'], className)}>
      <div
        className={styles['network-card__heading']}
        style={{ backgroundColor: bg }}
      >
        <Icon
          className={styles['network-card__heading-icon']}
          name={`networks/${slug}/${slug}`}
        />
        <div
          className={styles['network-card__heading-text']}
          style={{ color }}
          dangerouslySetInnerHTML={{ __html: header }}
        />
      </div>
      <div className={styles['network-card__summary']}>
        <div
          className={styles['network-card__summary-group']}
          dangerouslySetInnerHTML={{ __html: text }}
        ></div>
      </div>

      <div className={styles['network-card__footer']}>
        {slug === 'ru-tickets-new' ? (
          <Button
            title={t('forms:buttons.connectionRequest') as string}
            className={styles['network-card__button']}
            as={Link}
            href={{
              pathname: routes.SUPPORT,
              query: { title: t('networks:suggest_service') },
              external: true
            }}
            size={'m'}
            before={<Icon name={'arrows/right/24'} />}
          >
            {t('forms:buttons.connectionRequest')}
          </Button>
        ) : (
          <Button
            title={t('forms:buttons.more') as string}
            className={styles['network-card__button']}
            as={Link}
            href={`${routes.NETWORKS}/${slug}`}
            size={'m'}
            before={<Icon name={'arrows/right/24'} />}
          >
            {t('forms:buttons.more')}
          </Button>
        )}
      </div>
    </div>
  )
}
export default NetworkCard
