import clsx from 'clsx'
import { FC, ReactNode } from 'react'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './WarningMessage.module.scss'

interface WarningMessageProps extends DefaultComponentProps {
  before?: ReactNode
  after?: ReactNode
  children: ReactNode
}

const WarningMessage: FC<WarningMessageProps> = ({
  className = '',
  before = null,
  after = null,
  children = null
}) => {
  return (
    <div className={clsx(styles['warning-message'], className)}>
      <>
        {before && (
          <div className={styles['warning-message__before']}>{before}</div>
        )}
        <div className={styles['warning-message__message']}>{children}</div>
        {after && (
          <div className={styles['warning-message__after']}>{after}</div>
        )}
      </>
    </div>
  )
}
export default WarningMessage
