import { useTranslation } from 'next-i18next'
import { NextSeo } from 'next-seo'
import dynamic from 'next/dynamic'
import { ElementType, FC, useEffect, useMemo } from 'react'

import Fallback from '@/components/Fallback'

import { useUi } from '@/bus/ui'
import { useUser } from '@/bus/user'
import { routes } from '@/config'
import { CheckEmail, RecoveryPassword } from '@/contents'
import { DoublePanel } from '@/layouts'

export const Recovery: FC = () => {
  const {
    t,
    i18n: { language }
  } = useTranslation()
  const { data: { recoveryEmail: email } = {}, isAuthorized } = useUser()
  const { setSettings } = useUi()

  if (isAuthorized) {
    location.href = routes.SERVICES.replace(':lang', language)
  }

  const RandomBenefitsCard = useMemo(
    () =>
      dynamic(() => import(`@/components/BenefitsCard/RandomBenefitsCard`), {
        ssr: false,
        loading: () => <Fallback />
      }) as ElementType,
    []
  )

  useEffect(() => {
    setSettings({ recovery: true, menu: false, footer: false })
    return () => {
      setSettings({ recovery: false })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <NextSeo title={t('recovery:head.title') as string} />
      <DoublePanel side={<RandomBenefitsCard />}>
        {email ? <CheckEmail /> : <RecoveryPassword />}
      </DoublePanel>
    </>
  )
}
