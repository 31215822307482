import { useTranslation } from 'next-i18next'
import { FC, useEffect } from 'react'

import Heading from '@/components/Heading'
import NetworkCard from '@/components/NetworkCard'
import NetworkProps from '@/components/NetworkCard/NetworkProps.interface'

import styles from './Networks.module.scss'
import { useTexts } from '@/bus/texts'
import { useUi } from '@/bus/ui'
import { AboutNetworks, Reward } from '@/sections'

interface Props {
  networks?: {
    items: NetworkProps[]
    header: string
    updated_at: string
  }
}

export const Networks: FC<Props> = ({ networks = {} }) => {
  const {
    i18n: { language = 'ru' }
  } = useTranslation()

  const { setSettings } = useUi()
  const { getTextsBySlug, clearTexts, data = {} } = useTexts()

  useEffect(() => {
    getTextsBySlug({ slug: 'networks', lang: language })
    setSettings({ menu: true, footer: true })

    return () => {
      clearTexts()
    }
    // eslint-disable-next-line
  }, [])

  const {
    items = [],
    header = '',
    updated_at = null
  } = { ...networks, ...data }

  if (!updated_at) {
    return null
  }

  return (
    <>
      <AboutNetworks />
      <section className={styles['networks']}>
        <div className={styles['networks__inner']}>
          <Heading level={3} className={styles['networks__heading']}>
            {header}
          </Heading>
          <div className={styles['networks__list']}>
            {items.map(({ slug = '', ...rest }: NetworkProps) => (
              <NetworkCard
                key={slug}
                className={styles['networks__list-item']}
                slug={slug.replace('networks-', '')}
                {...rest}
              />
            ))}
          </div>
        </div>
      </section>
      <Reward />
    </>
  )
}
