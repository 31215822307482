import clsx from 'clsx'
import { FC, useCallback, useEffect, useRef, useState } from 'react'

import Heading from '@/components/Heading'
import Icon from '@/components/Icon'

import TextsProps from '@/types/TextsProps'

import styles from './Accordion.module.scss'

interface AccordionProps {
  items: TextsProps[]
}
const Accordion: FC<AccordionProps> = ({ items = [] }) => {
  const itemsRef = useRef<any>({})
  const [itemsRect, setItemsRect] = useState<{ [k: string]: number }>({})
  const [sliderActiveSlug, setSliderActiveSlug] = useState<null | string>(null)

  useEffect(() => {
    const rects = Object.entries(itemsRef.current).reduce(
      (acc, [k, v]) => ({
        ...acc,
        [k]: (v as HTMLDivElement).getBoundingClientRect().top
      }),
      {}
    )
    setItemsRect(rects)
  }, [itemsRef])

  useEffect(() => {
    if (sliderActiveSlug === null) {
      return
    }

    if (sliderActiveSlug === '') {
      history.pushState('', document.title, window.location.pathname)
      return
    }

    history.pushState(
      '',
      document.title,
      window.location.pathname + '#' + sliderActiveSlug
    )

    const header = document.getElementById('header')
    const { height = 0 } = header?.getBoundingClientRect() ?? {}
    window.scrollTo({
      top: itemsRect[sliderActiveSlug] - height,
      behavior: 'smooth'
    })
  }, [itemsRect, sliderActiveSlug])

  useEffect(() => {
    const hash = location.hash
    if (hash.length) {
      setSliderActiveSlug(hash.replace('#', ''))
    }
  }, [])

  const handleClick = useCallback((slug: string) => {
    setSliderActiveSlug(prevState => (slug === prevState ? '' : slug))
  }, [])

  if (items.length === 0) {
    return null
  }
  return (
    <div className={styles['accordion']}>
      {items.map(
        ({ header = '', text = '', items = [] }: TextsProps, index: number) => (
          <div className={styles['accordion__section']} key={index}>
            <Heading className={styles['accordion__section-heading']} level={6}>
              {header}
            </Heading>
            <div
              className={styles['accordion__section-summary']}
              dangerouslySetInnerHTML={{ __html: text }}
            />

            {items.length && (
              <ul className={styles['accordion__list']}>
                {items.map(
                  ({ header = '', text = '', slug = null }: TextsProps, i) => (
                    <li
                      className={clsx(styles['accordion__item'], {
                        [styles['accordion__item_active']]: [
                          slug,
                          `${index}-${i}`
                        ].includes(sliderActiveSlug)
                      })}
                      key={i}
                    >
                      <Heading
                        className={styles['accordion__item-heading']}
                        level={6}
                        onClick={() => handleClick(slug || `${index}-${i}`)}
                      >
                        <div
                          ref={el =>
                            (itemsRef.current[slug || `${index}-${i}`] = el)
                          }
                        >
                          {header}
                          <Icon
                            className={styles['accordion__item-heading-icon']}
                            name={'arrows/down/24'}
                          />
                        </div>
                      </Heading>
                      <div className={styles['accordion__item-content']}>
                        <div
                          style={{ minHeight: 0 }}
                          dangerouslySetInnerHTML={{ __html: text }}
                        />
                      </div>
                    </li>
                  )
                )}
              </ul>
            )}
          </div>
        )
      )}
    </div>
  )
}
export default Accordion
