import { useTranslation } from 'next-i18next'
import { FC } from 'react'

import RegisterForm from '@/components/Forms/RegisterForm'
import Heading from '@/components/Heading'
import Icon from '@/components/Icon'
import Link from '@/components/Link'

import styles from './Register.module.scss'
import { routes } from '@/config'

export const Signup: FC = () => {
  const { t } = useTranslation()

  return (
    <div className={styles['register']}>
      <Heading className={styles['register__heading']} level={3}>
        {t('signup:heading')}
      </Heading>
      <div className={styles['register__summary']}>
        <Link
          className={styles['register__by-admitad']}
          href={{ pathname: routes.LOGIN_BY_ADMITAD, external: true }}
          title={t('forms:buttons.registerByAdmitad') as string}
        >
          <span className={styles['register__by-admitad-text']}>
            {t('forms:buttons.registerByAdmitad')}
          </span>
          <span className={styles['register__by-admitad-icon']}>
            <Icon name={'admitad/43'} />
          </span>
        </Link>
      </div>
      <RegisterForm />
    </div>
  )
}
