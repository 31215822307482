import { useTranslation } from 'next-i18next'
import { FC } from 'react'

import RecoveryForm from '@/components/Forms/RecoveryForm'
import Heading from '@/components/Heading'

import styles from './Recovery.module.scss'

export const RecoveryPassword: FC = () => {
  const { t } = useTranslation()
  return (
    <div className={styles['recovery']}>
      <Heading className={styles['recovery__heading']} level={3}>
        {t('recovery:heading')}
      </Heading>
      <RecoveryForm />
    </div>
  )
}
